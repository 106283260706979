@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';
@import '../../assets/styles/typography';

.imagecard-wrapper {
  height: auto;
  display: flex;
  justify-content: center;
  .MuiButton-label {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
  }
  .imagecard-header-wrapper {
    @include max-screen(small) {
      max-width: 100%;
    }
    @include min-screen(small) {
      max-width: 312px;
    }
  }
  .imagecard {
    min-width: 100%;
    margin: 10px;

    .imagecard-image {
      max-width: 100%;
      max-height: 100%;
      aspect-ratio: 16/9;
    }
    .imagecard-button {
      color: white;
      background-color: $accent-purple;
      padding: 12px 32px;
      font-size: 1.8rem;
      min-width: 64px;
      &:hover {
        background-color: $accent-purple;
        box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
          0px 1px 10px 0px rgba(0, 0, 0, 0.12);
      }
    }
    .imagecard-heading {
      @include heading-font();
      text-decoration: none;
    }
    .imagecard-desc {
      @include body-font();
      color: $cool-grey-11;
      text-decoration: none;
      margin-top: 10px;
    }

    .imagecard-link-container {
      padding: 8px;
    }

    .imagecard-aa {
      text-decoration: none;
    }
  }
}
