@import '../../../assets/styles/_variables.scss';
@import '../../../assets/styles/_typography.scss';
@import '../../../assets/styles/_accessibility.scss';

.reset-button-hide {
  visibility: visible;
}

// Search Box Styles
.search-field-wrapper {
  border-bottom: 12px solid $croatia-secondary;
  .cancel-search {
    display: none;
  }
  @include min-screen(small) {
    border-left: 12px solid $croatia-secondary;
    border-bottom: none;
  }
  .search-field-border {
    background: linear-gradient(99deg, rgba(77, 16, 74, 1) 0%, rgba(43, 6, 34, 1) 100%);
    padding: 20px 10px;
    border-bottom: 3px solid $white;
    @include min-screen(small) {
      border-left: 3px solid $white;
      border-bottom: none;
    }
    .search-field-frame {
      background-color: #fff;
      border: 1px solid #dbd9d6;
      border-radius: 20px;
      display: inline-block;
      height: 35px;
      width: 100%;
      margin: 0 auto;
      &:focus-within {
        @include outline-accessability();
      }
      .headless-search-field-input {
        font-size: 16px;
        width: calc(100% - 105px);
        height: 20px;
        margin: 6px 0 0 10px;
        border: none;
        &:focus {
          outline: none;
        }
        &::placeholder {
          color: #666d70;
          opacity: 1;
        }
      }
      .site-search-search-button {
        display: inline-block;
        margin-right: 10px;
        input {
          background: url(../../../assets/images/iconography/icon-search-magnifer.svg);
          background-position: center;
          background-repeat: no-repeat;
          text-indent: -9999px;
          width: 15px;
          border: none;
          cursor: pointer;
          z-index: 1000;
          &:focus {
            outline-offset: 0px;
          }
        }
      }
      .site-search-reset-button {
        display: inline-block;
        border-right: 1px solid $cool-grey-1;
        padding-right: 10px;
        margin-right: 10px;
        width: 18px;
        input {
          background: url(../../../assets/images/iconography/icon-search-close.svg);
          background-position: center;
          background-repeat: no-repeat;
          text-indent: -9999px;
          margin-right: 5px;
          cursor: pointer;
          border: none;
          z-index: 1000;
          width: 100%;
          &:focus {
            outline-offset: 0px;
          }
        }
      }
    }
  }
}
