@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';
@import '../../assets/styles/typography';

.green-body-icons {
  // background: url('../../assets/images/iconography/iconography-green-body-end-left.svg') no-repeat;
  // width: 100%;
  // height: 229px;
  display: none;
  // & + .callout {
  //  margin: 0 0 60px 0;
  // }
}

.callout {
  margin: 0 0 60px 0;
  padding: 24px;

  .MuiButton-label {
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
  }
  &.primary-background {
    background-color: $primary-purple;
    padding: 0;
    .callout-background {
      background: url('../../assets/images/iconography/croatia/Callout1-Iconography.svg') no-repeat
          0% 0%,
        padding-box;
      background-size: auto;
      @include min-screen(medium) {
        background: url('../../assets/images/iconography/croatia/Callout1-Iconography.svg')
            no-repeat calc(0% + 60px) 100%,
          padding-box;
        background-size: auto;
      }
      @include max-screen(medium) {
        background: url('../../assets/images/iconography/croatia/mobileCallout1-Iconography.svg')
            no-repeat left top,
          padding-box;
        background-size: auto;
      }
    }

    @include min-screen(medium) {
      padding: 0;
    }
    &.image-right {
      .callout-background {
        @include min-screen(medium) {
          background-position: calc(100% - 60px) 100%;
        }
      }
    }
    .callout-wrapper {
      padding: 60px 20px;
      @include min-screen(medium) {
        padding: 60px 0 60px 0;
      }

      .image-column {
        img {
          max-width: 100%;
        }
      }
      .text-column {
        width: 100%;
        .primary-contrast {
          color: $primary-purple;
        }

        @include min-screen(medium) {
          width: 34.3%;
        }
        .subheading {
          color: $croatia-secondary;
        }
        .description {
          color: $white;
          p {
            color: $white;
          }
          ul {
            li {
              &::marker {
                color: $white;
              }
            }
          }
          a {
            color: $croatia-secondary;
            text-decoration: underline;
            .cta,
            button {
              background-color: $croatia-secondary;
            }
          }
        }
      }
      &.image-top,
      &.image-bottom {
        align-items: center;
        .text-column {
          width: 100%;
          @include min-screen(medium) {
            width: 34.3%;
          }
        }
      }
      &.image-right {
        .text-column {
          @include min-screen(medium) {
            margin-right: 60px;
          }
        }
      }
      &.image-left {
        .text-column {
          @include min-screen(medium) {
            margin-left: 60px;
          }
        }
      }
    }
  }
  //Full Width Grey Background
  &.alternate-background {
    background: linear-gradient(108deg, #ffffff 0%, #efefef 100%);
    padding: 0;
    .callout-background {
      background: url('../../assets/images/iconography/croatia/Callout2-Iconography.svg') no-repeat
          0% 0%,
        padding-box;
      background-size: auto;
      @include min-screen(medium) {
        background: url('../../assets/images/iconography/croatia/Callout2-Iconography.svg')
            no-repeat calc(100% - 60px) 100%,
          padding-box;
        padding: 0;
        background-size: auto;
      }
      @include max-screen(medium) {
        background: url('../../assets/images/iconography/croatia/mobileCallout2-Iconography.svg')
            no-repeat left top,
          padding-box;
        background-size: auto;
      }
    }

    @include min-screen(medium) {
      padding: 0;
    }
    &.image-left {
      .callout-background {
        @include min-screen(medium) {
          background-position: calc(0% + 60px) 100%;
        }
      }
    }
    .callout-wrapper {
      padding: 60px 20px;
      @include min-screen(medium) {
        padding: 60px 0 60px 0;
      }
      .image-column {
        img {
          max-width: 100%;
        }
      }
      .text-column {
        width: 100%;
        @include min-screen(medium) {
          width: 34.3%;
        }
        .description {
          color: $cool-grey-11;
          p {
            color: $cool-grey-11;
          }
        }
      }
      &.image-top,
      &.image-bottom {
        align-items: center;
        .text-column {
          width: 100%;
          @include min-screen(medium) {
            width: 34.3%;
          }
        }
      }
      &.image-right {
        .text-column {
          @include min-screen(medium) {
            margin-right: 60px;
          }
        }
      }
      &.image-left {
        .text-column {
          @include min-screen(medium) {
            margin-left: 60px;
          }
        }
      }
    }
  }
  //listing design
  &.listing-design {
    padding: 0;
    .callout-wrapper {
      @include max-screen(medium) {
        flex-direction: column;
        align-items: center;
      }
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex-direction: row;

      @include min-screen(medium) {
        padding: 0;
      }
      &.image-right {
        @include max-screen(medium) {
          flex-direction: column;
        }
        flex-direction: row-reverse;
      }
      .image-column {
        @media (min-width: 960px) and (max-width: 1280px) {
          max-width: 40%;
        }
        a {
          img {
            max-width: 100%;
          }
        }
      }
      .text-column {
        width: 100%;
        .subheading {
          @include heading-font();
          @include font-size(2.4);
          color: $primary-purple;
          margin-top: 24px;
          display: block;
          @include min-screen(medium) {
            @include font-size(2.4);
            margin-top: -5px;
          }
        }
        .description {
          @include body-font();
          @include font-size(1.8);
          color: $cool-grey-11;
          :last-child {
            margin-bottom: 0;
          }
          p {
            color: $cool-grey-11;
          }
        }
        a {
          text-decoration: none;
          .cta,
          button {
            margin-top: 20px;
            width: 100%;
            background-color: $accent-purple;
            @include min-screen(medium) {
              width: initial;
            }
          }
        }
      }
      &.image-right {
        .text-column {
          @include min-screen(medium) {
            margin-right: 60px;
          }
        }
      }
      &.image-left {
        .text-column {
          @include min-screen(medium) {
            margin-left: 60px;
          }
        }
      }
    }
  }
  //Regular non Full Width Styles
  .callout-wrapper {
    @include max-screen(medium) {
      flex-direction: column;
      align-items: center;
    }
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;
    @include min-screen(medium) {
      padding: 58px 0 63px 0;
    }
    &.image-right {
      @include max-screen(medium) {
        flex-direction: column;
      }
      flex-direction: row-reverse;
    }
    &.image-top {
      @include max-screen(medium) {
        flex-direction: column;
      }
      flex-direction: column;
      .image-column {
        margin-bottom: 60px;
        @media (min-width: 960px) and (max-width: 1280px) {
          max-width: 100%;
        }
      }
      .text-column {
        width: 100%;
      }
    }
    &.image-bottom {
      @include max-screen(medium) {
        flex-direction: column-reverse;
      }
      flex-direction: column-reverse;
      .text-column {
        margin-bottom: 60px;
        width: 100%;
      }
      .image-column {
        @media (min-width: 960px) and (max-width: 1280px) {
          max-width: 100%;
        }
      }
    }

    .image-column {
      @media (min-width: 960px) and (max-width: 1280px) {
        max-width: 40%;
      }
      a {
        img {
          max-width: 100%;
        }
      }
    }
    .text-column {
      width: 100%;
      @include min-screen(medium) {
        width: 34.3%;
      }
      .subheading {
        @include heading-font();
        @include font-size(2.4);
        color: $primary-purple;
        margin-top: 24px;
        display: block;
        @include min-screen(medium) {
          @include font-size(2.4);
          margin-top: -5px;
        }
      }
      .description {
        @include body-font();
        @include font-size(1.8);
        color: $cool-grey-11;
        :last-child {
          margin-bottom: 0;
        }
        p {
          color: $cool-grey-11;
        }
      }
      a {
        text-decoration: underline !important;
        button {
          margin-top: 20px;
          width: 100%;
          @include min-screen(medium) {
            width: initial;
          }
        }
      }
    }
    &.image-right {
      .text-column {
        @include min-screen(medium) {
          margin-right: 60px;
        }
      }
    }
    &.image-left {
      .text-column {
        @include min-screen(medium) {
          margin-left: 60px;
        }
      }
    }
  }
}
