@import '_mixins.scss';
@import '_accessibility.scss';
@import '_variables.scss';

/* catamaran-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 400;
  src: url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Catamaran/catamaran-v17-latin-regular.eot'); /* IE9 Compat Modes */
  src: url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Catamaran/catamaran-v17-latin-regular.eot?#iefix') format('embedded-opentype'),
  /* IE6-IE8 */
  url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Catamaran/catamaran-v17-latin-regular.woff2') format('woff2'),
  /* Super Modern Browsers */
  url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Catamaran/catamaran-v17-latin-regular.woff') format('woff'),
  /* Modern Browsers */
  url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Catamaran/catamaran-v17-latin-regular.ttf') format('truetype'),
  /* Safari, Android, iOS */
  url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Catamaran/catamaran-v17-latin-regular.svg#Catamaran') format('svg'); /* Legacy iOS */
}
/* catamaran-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 500;
  src: url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Catamaran/catamaran-v17-latin-500.eot'); /* IE9 Compat Modes */
  src: url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Catamaran/catamaran-v17-latin-500.eot?#iefix') format('embedded-opentype'),
  /* IE6-IE8 */
  url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Catamaran/catamaran-v17-latin-500.woff2') format('woff2'),
  /* Super Modern Browsers */
  url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Catamaran/catamaran-v17-latin-500.woff') format('woff'),
  /* Modern Browsers */
  url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Catamaran/catamaran-v17-latin-500.ttf') format('truetype'),
  /* Safari, Android, iOS */
  url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Catamaran/catamaran-v17-latin-500.svg#Catamaran') format('svg'); /* Legacy iOS */
}
/* catamaran-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 600;
  src: url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Catamaran/catamaran-v17-latin-600.eot'); /* IE9 Compat Modes */
  src: url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Catamaran/catamaran-v17-latin-600.eot?#iefix') format('embedded-opentype'),
  /* IE6-IE8 */
  url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Catamaran/catamaran-v17-latin-600.woff2') format('woff2'),
  /* Super Modern Browsers */
  url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Catamaran/catamaran-v17-latin-600.woff') format('woff'),
  /* Modern Browsers */
  url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Catamaran/catamaran-v17-latin-600.ttf') format('truetype'),
  /* Safari, Android, iOS */
  url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Catamaran/catamaran-v17-latin-600.svg#Catamaran') format('svg'); /* Legacy iOS */
}
/* catamaran-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 700;
  src: url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Catamaran/catamaran-v17-latin-700.eot'); /* IE9 Compat Modes */
  src: url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Catamaran/catamaran-v17-latin-700.eot?#iefix') format('embedded-opentype'),
  /* IE6-IE8 */
  url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Catamaran/catamaran-v17-latin-700.woff2') format('woff2'),
  /* Super Modern Browsers */
  url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Catamaran/catamaran-v17-latin-700.woff') format('woff'),
  /* Modern Browsers */
  url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Catamaran/catamaran-v17-latin-700.ttf') format('truetype'),
  /* Safari, Android, iOS */
  url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Catamaran/catamaran-v17-latin-700.svg#Catamaran') format('svg'); /* Legacy iOS */
}
//Catamaran font weights Regular 400 thru Bold 700
//Montserrat font weight 500 - 800.. no italics
/* montserrat-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Montserrat/montserrat-v25-latin-500.eot'); /* IE9 Compat Modes */
  src: url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Montserrat/montserrat-v25-latin-500.eot?#iefix') format('embedded-opentype'),
  /* IE6-IE8 */
  url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Montserrat/montserrat-v25-latin-500.woff2') format('woff2'),
  /* Super Modern Browsers */
  url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Montserrat/montserrat-v25-latin-500.woff') format('woff'),
  /* Modern Browsers */
  url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Montserrat/montserrat-v25-latin-500.ttf') format('truetype'),
  /* Safari, Android, iOS */
  url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Montserrat/montserrat-v25-latin-500.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Montserrat/montserrat-v25-latin-600.eot'); /* IE9 Compat Modes */
  src: url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Montserrat/montserrat-v25-latin-600.eot?#iefix') format('embedded-opentype'),
  /* IE6-IE8 */
  url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Montserrat/montserrat-v25-latin-600.woff2') format('woff2'),
  /* Super Modern Browsers */
  url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Montserrat/montserrat-v25-latin-600.woff') format('woff'),
  /* Modern Browsers */
  url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Montserrat/montserrat-v25-latin-600.ttf') format('truetype'),
  /* Safari, Android, iOS */
  url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Montserrat/montserrat-v25-latin-600.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Montserrat/montserrat-v25-latin-700.eot'); /* IE9 Compat Modes */
  src: url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Montserrat/montserrat-v25-latin-700.eot?#iefix') format('embedded-opentype'),
  /* IE6-IE8 */
  url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Montserrat/montserrat-v25-latin-700.woff2') format('woff2'),
  /* Super Modern Browsers */
  url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Montserrat/montserrat-v25-latin-700.woff') format('woff'),
  /* Modern Browsers */
  url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Montserrat/montserrat-v25-latin-700.ttf') format('truetype'),
  /* Safari, Android, iOS */
  url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Montserrat/montserrat-v25-latin-700.svg#Montserrat') format('svg'); /* Legacy iOS */
}
/* montserrat-800 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 800;
  src: url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Montserrat/montserrat-v25-latin-800.eot'); /* IE9 Compat Modes */
  src: url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Montserrat/montserrat-v25-latin-800.eot?#iefix') format('embedded-opentype'),
  /* IE6-IE8 */
  url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Montserrat/montserrat-v25-latin-800.woff2') format('woff2'),
  /* Super Modern Browsers */
  url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Montserrat/montserrat-v25-latin-800.woff') format('woff'),
  /* Modern Browsers */
  url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Montserrat/montserrat-v25-latin-800.ttf') format('truetype'),
  /* Safari, Android, iOS */
  url('https://ep-asset-upmccom-f3gea2gwete8h7fc.z01.azurefd.net/fonts/Montserrat/montserrat-v25-latin-800.svg#Montserrat') format('svg'); /* Legacy iOS */
}

$montserrat: 'Montserrat', sans-serif;
$catamaran: 'Catamaran', sans-serif;

// Font Mixins =============================================== *

@mixin heading-font {
  font-family: $montserrat;
  font-weight: 700;
  color: $primary-purple;
}

@mixin body-font {
  font-family: $montserrat;
  font-weight: 500;
  color: $cool-grey-11;
}

@mixin h1 {
  @include heading-font();
  @include font-size(3); //mobile
  margin-left: 18px;

  @media (min-width: 1263px) {
    margin-left: 40px;
  }

  @media (min-width: 1920px) {
    margin-left: 100px;
  }

  @include min-screen(large) {
    @include font-size(5.2);
  }

  @include min-screen(xlarge) {
    @include font-size(6.4);
  }
}

@mixin h2 {
  @include heading-font();
  @include font-size(2.4); //mobile

  @include min-screen(medium) {
    @include font-size(4.2);
  }

  @include min-screen(xlarge) {
    @include font-size(5.2);
  }
}

@mixin h3 {
  @include heading-font();
  @include font-size(2.2); //mobile

  @include min-screen(medium) {
    @include font-size(3.6);
  }

  @include min-screen(xlarge) {
    @include font-size(4.2);
  }
}

@mixin h4 {
  @include heading-font();
  @include font-size(2); //mobile

  @include min-screen(medium) {
    @include font-size(2.8);
  }

  @include min-screen(xlarge) {
    @include font-size(3.6);
  }
}

@mixin h5 {
  @include heading-font();
  @include font-size(1.8); //mobile
  margin-bottom: 18px;

  @include min-screen(medium) {
    @include font-size(2.2);
  }

  @include min-screen(xlarge) {
    @include font-size(2.8);
  }
}

@mixin h6 {
  @include heading-font();
  @include font-size(1.6); //mobile

  @include min-screen(medium) {
    @include font-size(1.8);
  }

  @include min-screen(xlarge) {
    @include font-size(2.2);
  }
}

body {
  @include body-font();
  @include font-size(2);

  @include min-screen(medium) {
    @include font-size(1.8);
  }

  @include min-screen(xlarge) {
    @include font-size(2.4);
  }
}

h1 {
  @include heading-font();
  @include font-size(3); //mobile
  margin-left: 18px;

  @media (min-width: 1263px) {
    margin-left: 100px;
  }
}

h2 {
  @include heading-font();
  @include font-size(2.4); //mobile
}

h3 {
  @include heading-font();
  @include font-size(2.2); //mobile
}

h4 {
  @include heading-font();
  @include font-size(2); //mobile
}

h5 {
  @include heading-font();
  @include font-size(1.8); //mobile
  margin-bottom: 18px;
}

h6 {
  @include heading-font();
  @include font-size(1.6); //mobile
}

/* Base anchor link styles */
a {
  @include anchor-link-styles($dark-cyan, $deep-mauve, $accent-purple, $upmc-purple, true);
}

/* Header anchor link styles */
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
  @include anchor-link-styles($accent-purple, $accent-purple, $accent-purple, $accent-purple, true);
}

strong,
b {
  font-weight: 700;
}

ul {
  li {
    &::marker {
      color: $accent-purple;
    }
  }
}
