@import '../../assets/styles/typography';
@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

footer {
  .simple-footer-wrapper {
    border-bottom: 10px solid $croatia-secondary;
    .footer-fullwidth-wrapper {
      margin-top: 40px;
      width: 100%;
      max-width: 100%;
      .footer-legal-container {
        color: #fff;
        padding: 0;
        background: $primary-purple;
        @include min-screen(small) {
          text-align: center;
        }
        .MuiGrid-container {
          justify-content: center;
        }
        .footer-legal-spacing {
          padding: 12px 0;
          @include min-screen(small) {
            padding: 24px 0;
          }
          .footer-legal-grid {
            padding: 12px;
            .linklistcontainer {
              .linklist {
                list-style: none;
                margin: 0;
                padding: 0;
                .linklistitem {
                  margin: 0 10px 0 0;
                  display: block;
                  @include max-screen(medium) {
                    padding: 0px 0 0px 0;
                  }
                  @include min-screen(small) {
                    display: inline-block;
                  }
                  a {
                    @include body-font();
                    @include font-size(1.6);
                    color: $white;
                  }
                }
              }
            }
          }
        }
      }

      .footer-copyright-container {
        padding: 12px;
        @include max-screen(medium) {
          text-align: center;
        }
        span {
          color: $white;
          @include font-size(1.6);
        }
        .linklistcontainer {
          display: inline-block;
          .linklist {
            display: inline-flex;
          }
        }
      }
      .footerSiteLinks {
        min-height: 351px;
        color: $cool-grey-11;
        padding: 0 48px 96px 48px;

        .four-column-content-row .MuiGrid-item {
          padding: 18px 9px;
        }

        @include min-screen(small) {
          padding: 0 64px 96px 64px;
        }
        @include min-screen(medium) {
          padding: 0 96px 88px 96px;
        }
        .linklistcontainer {
          .linklist {
            margin: 0;
            padding: 0;
            .linklistitem {
              a {
                margin-bottom: 5px;
                display: block;
                @include body-font();
                @include font-size(1.8);
                color: $cool-grey-11;
                text-decoration: none;
                &:hover {
                  text-decoration: underline;
                  color: $deep-mauve;
                }
                &:active {
                color: $upmc-purple;
                }
                &:visited {
                  text-decoration: none;
                  color: $accent-purple;
                }
                &:focus {
                  color: $cool-grey-11;
                  outline: 2px solid $deep-mauve;
                  outline-offset: 0px;
                }
                &.footer-sitemap-viewall {
                  display: block;
                  text-decoration: underline;
                  margin-top: 10px;
                }
                &.footer-sitemap-header {
                  padding: 0;
                  @include heading-font();
                  color: $cool-grey-11;
                  @include font-size(2);
                  margin-bottom: 16px;
                  display: block;
                }
              }
            }
          }
        }
        .MuiContainer-maxWidthLg {
          &:last-child {
            margin-top: 0;
          }
        }
        .content-row + .content-row .MuiGrid-item {
          margin-top: 0;
        }
        @include max-screen(medium) {
          .content-row {
            &:first-child {
              .MuiGrid-item {
                &:first-child {
                  margin-top: 40px;
                }
              }
            }
            .MuiGrid-item {
              margin-top: 0;
            }
          }
        }
      }
    }
    .footer-bottom-border {
      border-bottom: 10px solid $croatia-secondary;
      margin: 2px 0 0 0;
    }
    .footer-search-wrapper {
      text-align: center;
      background-color: $alto-grey;
      height: 68px;
      display: flex;
      align-items: center;
      padding: 24px 20px;
      box-sizing: content-box;
      .search-box-container {
        width: 100%;
        .search-field-wrapper {
          border: none;
          .search-field-border {
            border: none;
            padding: 0px;
            background: none;
            .headless-search-field-input {
              width: calc(100% - 60px);
            }
            .site-search-reset-button {
              display: none;
            }
          }
          &:focus-within {
            .search-field-border {
              .search-field-frame {
                .site-search-reset-button {
                  display: inline-block;
                }
                .headless-search-field-input {
                  width: calc(100% - 99px);
                }
              }
            }
          }
        }
      }
      @include min-screen(medium) {
        border-radius: 34px;
        text-align: left;
        padding: 0 20px;
        margin: 0 auto;
      }
    }
    .footer-search-wrapper-grid {
      align-items: center;
      .footer-search-field-label {
        margin-bottom: 10px;
        display: block;
        @include min-screen(medium) {
          margin-bottom: 0px;
        }
      }
    }
  }

  @include min-screen(small) {
    footer {
      .linklistcontainer {
        .linklist {
          .linklistitem {
            display: inline-block;
            a {
              color: $white;
              text-decoration: underline;
              &:hover {
                color: $accent-purple;
              }
            }
          }
        }
      }
    }
  }
}
