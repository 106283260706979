@import '../../assets/styles/typography';
@import '../../assets/styles/variables';
@import '../../assets/styles/mixins';

.header-logo-only {
  nav {
    /*.utility-navigation {
      display: flex;
      border-bottom: 2px solid $cool-grey-1;
      align-items: center;
      justify-content: center;
      padding: 0 18px;
      @include min-screen(large) {
        padding: 18px 100px;
        justify-content: flex-start;
      }
    }*/
    .logo-container {
      /*margin: 30px 0 0;
      padding: 0 18px;
      @include min-screen(large) {
        padding: 0 100px;
        margin: 40px 0 0;
      }
      */
      .logo {
        img {
          vertical-align: middle;
          max-width: 98%;
        }
        &.center {
          text-align: center;
        }
        &.right {
          margin-left: calc(100% - 180px);
        }
      }
    }
  }
  &.scEnabledChrome {
    nav {
      .utility-navigation {
        .phone-number {
          display: block;
          width: 200px;
        }
      }
    }
  }
}
