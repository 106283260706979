.MobileNavigation {
  .mobile-menu-button {
    padding: 0;
    margin-left: 32px;
  }
}
.mobile-drawer {
  top: 120px !important;
  &.inner-drawer {
    top: 120px;
    min-width: 100vw;
  }
  .MuiDrawer-paper {
    top: 120px !important;
    width: 100%;
    height: calc(100% - 120px);
  }
  .MuiBox-root {
    min-width: 100%;
  }
}

.emergency-alert-wrapper {
  & + .toolbar {
    .mobile-drawer {
      top: 266px !important;
      &.inner-drawer {
        top: 266px;
      }
      .MuiDrawer-paper {
        top: 266px !important;
        width: 100%;
        height: calc(100% - 266px);
      }
    }
  }
}
