@import '_variables.scss';

// MEDIA QUERIES =============================================== *

// Min-width
@mixin min-screen($breakpoint) {
  // Retrieves the value from the key
  $value: map-get($breakpoints, $breakpoint);

  // If the key exists in the map
  @if $value !=null {
    // Prints a media query based on the value
    @media (min-width: $value) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "No value could be retrieved from `#{$breakpoint}`. "
    +"Make sure it is defined in `$breakpoints` map.";
  }
}

// Max-width
@mixin max-screen($breakpoint) {
  // Retrieves the value from the key
  $value: map-get($breakpoints, $breakpoint);

  // If the key exists in the map
  @if $value !=null {
    // Prints a media query based on the value
    @media (max-width: $value) {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "No value could be retrieved from `#{$breakpoint}`. "
    +"Make sure it is defined in `$breakpoints` map.";
  }
}

// REM Conversion for font size =============================================== *
@mixin font-size($sizeValue: 1.6) {
  font-size: ($sizeValue * 10) + px;
  font-size: $sizeValue + rem;
  //Line height WCAG complinace
  line-height: 1.5;
}

// Psuedo used for all :after and :before elements =============================================== *
@mixin pseudo($display: block, $pos: absolute, $content: '') {
  content: $content;
  display: $display;
  position: $pos;
}

// Responsive Ratio Images (16 by 9, 4 by 5, etc) =============================================== *
@mixin responsive-ratio($x, $y, $pseudo: false) {
  $padding: unquote(($y / $x) * 100 + '%');

  @if $pseudo {
    &:before {
      @include pseudo($pos: relative);
      width: 100%;
      padding-top: $padding;
    }
  } @else {
    padding-top: $padding;
  }
}

//Triangles for menus and accordions =============================================== *
@mixin css-triangle($color, $direction, $size: 6px, $position: absolute, $round: false) {
  @include pseudo($pos: $position);
  width: 0;
  height: 0;

  @if $round {
    border-radius: 3px;
  }

  @if $direction==down {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
    margin-top: 0 - round($size / 2.5);
  } @else if $direction==up {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
    margin-bottom: 0 - round($size / 2.5);
  } @else if $direction==right {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-left: $size solid $color;
    margin-right: -$size;
  } @else if $direction==left {
    border-top: $size solid transparent;
    border-bottom: $size solid transparent;
    border-right: $size solid $color;
    margin-left: -$size;
  }
}

/* Define a mixin for anchor link and header link styles */
@mixin anchor-link-styles($color, $hover-color, $visited-color, $focus-color, $isBold) {
  color: $color;
  text-decoration: underline;
  font-weight: if($isBold, 700, 400);


  &:hover {
    color: $hover-color;
  }

  &:visited {
    color: $visited-color;
  }

  &:focus {
    color: $focus-color;
    @include outline-accessability();
    outline: 2px solid $focus-color;
    outline-offset: 2px;
  }
}

