@import '../../../assets/styles/typography';
@import '../../../assets/styles/variables';
@import '../../../assets/styles/mixins';

.left-navigation {
  display: none;
  @include min-screen(large) {
    display: block;
  }
  max-width: 246px;
  .section-title {
    background-color: #f3f3f3;
    padding: 5px 20px;
    span,
    span a {
      @include body-font();
      @include font-size(1.6);
      color: $cool-grey-11;
      font-weight: 700;
      text-decoration: none;
    }
    .active {
      color: $cool-grey-11;
      line-height: 1.2;
      cursor: default;
    }
  }
  .left-navigation-wrapper {
    padding: 0 15px 0 20px;
    .MuiAccordionDetails-root {
      padding: 0;
    }
    .linklist {
      list-style-type: none;
      margin: 0;
      padding: 0;
      width: 100%;
      .linklist {
        width: 100%;
        margin: 0 0 0 15px;
      }
    }
    .left-nav-heading,
    .linklistitem {
      line-height: 1.2;
      .MuiAccordionSummary-root {
        padding: 0;
        margin-top: 0;
        min-height: 30px;
        border-bottom: none;
        margin-bottom: -1px;
        display: flex;
      }
      .MuiAccordionSummary-content {
        margin: 0;
        display: flex;
        flex-grow: 1;
      }
      svg {
        width: 1em;
        height: 1em;
      }
      .MuiCollapse-hidden {
        display: none;
      }
      .MuiAccordionSummary-expandIcon {
        padding: 0;
      }
      .MuiAccordionSummary-expandIcon.Mui-expanded {
        transform: rotate(90deg);
      }
      .MuiAccordion-root {
        border: none;
        box-shadow: none;
      }
      &:not(:last-child) {
        border-bottom: 0;
      }
      &.Mui-expanded {
        margin: 0;
      }
      &:before {
        background-color: #ffffff;
      }
      div,
      a {
        @include body-font();
        @include font-size(1.4);
        color: $cool-grey-11;
        font-weight: 700;
        text-decoration: none;
      }
      .active {
        color: $accent-purple;
        cursor: default;
      }
    }
    .linklistitem {
      margin: 10px 0 0;
      &:first-child {
        margin: 15px 0;
      }
    }
  }
  .skipnavigation {
    a {
      position: absolute;
      z-index: 90000;
      left: -1000px;
      &:focus {
        left: 0;
        cursor: pointer;
        font-size: 16px;
        color: $upmc-purple;
        @include outline-accessability();
      }
    }
  }
}
