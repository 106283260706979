@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';
@import '../../assets/styles/typography';

.border-callout-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(1, 1fr);
  border: 10px solid;
  border-color: $croatia-secondary;

  @include max-screen(medium) {
    grid-template-rows: inherit;
  }
  .text-column {
    grid-area: 1 / 1 / 4 / 3;
    width: 80%;
    margin: 31px 0 0 32px;
    @include max-screen(medium) {
      grid-area: 2/1/3/5;
      margin: 0px auto 0;
    }
    @include max-screen(small) {
      width: 100%;
      padding: 0;
    }
    .title {
      margin-left: 0;
      margin-top: 0px;
      display: block;
      @include heading-font();
      @include font-size(3);
    }
    .additional-text {
      padding: 0 15px 0 0;
      margin-bottom: 0px;
      @include max-screen(small) {
      }
      p {
        font-family: $montserrat !important;
        @include font-size(2);
        color: $cool-grey-11;
      }
      span {
        font-family: $montserrat !important;
      }
    }
    .title,
    .additional-text {
      @include max-screen(small) {
        padding: 0px 15px 0px 15px;
        margin-top: 15px;
      }
    }
    .title,
    .additional-text {
      @include min-screen(small) {
        margin-left: 15px;
      }
    }
  }

  .image-container {
    grid-area: 1 / 1 / 4 / 5;
    z-index: -2;
    margin-bottom: -10px;
    @include max-screen(medium) {
      grid-area: 1/1/2/5;
    }
    img {
      width: 100%;
    }
  }
  .callout-button {
    margin-left: 15px;
    margin-top: 15px;
    margin-bottom: 24px;
    background-color: $accent-purple;
    color: white;
    &:hover {
      background-color: $accent-purple;
      box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14),
        0px 1px 10px 0px rgba(0, 0, 0, 0.12);
    }
    @include max-screen(small) {
      margin-bottom: 15px;
    }

    span {
      margin-right: 15px;
      margin-left: 15px;
    }
  }
}
