@import '../../assets/styles/accessibility';
@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.skiptocontent {
  a {
    position: absolute;
    z-index: 90000;
    top: -1000px;
    left: 0;

    @include min-screen(large) {
      left: 260px;
    }
    &:focus {
      @include min-screen(large) {
        top: 16px;
      }
      color: $upmc-purple;
      top: 0;
      cursor: pointer;
      font-size: 16px;
      @include outline-accessability();
    }
  }
}
