@import '_variables.scss';
@import '_accessibility';

.cta {
  color: $white;
  background-color: $accent-purple;
  padding: 12px 32px 13px 32px;
  // display: inline-block;
  width: 100%;
  text-align: center;
  font-size: 1.8rem;
  min-width: 64px;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: 'Catamaran', sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 25px;
  text-transform: inherit;
  text-decoration: none;
  display: block;

  &:hover {
    background-color: $accent-purple;
    color: $white;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }

  &:focus {
    background-color: $accent-purple;
    color: $white;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }

  &:visited {
    color: $white;
  }

  &.secondary {
    background-color: transparent;
    color: $accent-purple;
    border: 2px solid $accent-purple;

    &:visited {
      color: $accent-purple;
    }

    &:hover,
    &:focus {
      color: $accent-purple;
      border-color: $accent-purple;
      background-color: transparent;
    }

    &:focus {
      @include outline-accessability();
      outline: 2px solid $accent-purple;
    }
  }

  &.disabled {
    background-color: $iron-grey;
    color: $silver-foil;

    :hover,
    :focus {
      background-color: $accent-purple;
      color: $white;
      outline: none;
      outline-offset: 0;
      cursor: default;
    }
  }
}
